<template>
  <div class="relative sorry w-full items-center justify-center">
    <div>
      <div class="img-wrap relative">
        <img src="@/assets/images/sorry.png" alt="Sorry" class="mx-auto my-5 xl:my-10 max-w-[360px]">
        <div class="shadow-circle" />
      </div>

      <h2 class="f-trap fw-700 text-[60px] mt-[60px]">
        Sorry
      </h2>
      <div class="fw-500 text-[20px] mt-4">
        ChuChamp only supports playing on phones.
      </div>
    </div>
  </div>
</template>

<style scoped>
.shadow-circle {
  background-color: #262626;
  border-radius: 70%;
  width: 365px;
  height: 74px;
  z-index: -1;
  margin-top: -86px;
  margin-left: 31px;
}
</style>
