const __pages_import_0__ = () => import("/src/pages/signup.vue");
const __pages_import_1__ = () => import("/src/pages/portfolio.vue");
const __pages_import_2__ = () => import("/src/pages/login.vue");
import __pages_import_3__ from "/src/pages/index.vue";
const __pages_import_4__ = () => import("/src/pages/home.vue");
const __pages_import_5__ = () => import("/src/pages/avatar.vue");
const __pages_import_6__ = () => import("/src/pages/[...error].vue");
const __pages_import_7__ = () => import("/src/pages/competition/[type].vue");
const __pages_import_8__ = () => import("/src/pages/upload/[type].vue");
const __pages_import_9__ = () => import("/src/pages/uploaded/[type].vue");

const routes = [{"name":"signup","path":"/signup","component":__pages_import_0__,"props":true},{"name":"portfolio","path":"/portfolio","component":__pages_import_1__,"props":true,"meta":{"requiresAuth":true}},{"name":"login","path":"/login","component":__pages_import_2__,"props":true},{"name":"index","path":"/","component":__pages_import_3__,"props":true},{"name":"home","path":"/home","component":__pages_import_4__,"props":true,"meta":{"requiresAuth":true}},{"name":"avatar","path":"/avatar","component":__pages_import_5__,"props":true,"meta":{"requiresAuth":true}},{"name":"error","path":"/:error(.*)*","component":__pages_import_6__,"props":true},{"name":"competition-type","path":"/competition/:type","component":__pages_import_7__,"props":true,"meta":{"requiresAuth":true}},{"name":"upload-type","path":"/upload/:type","component":__pages_import_8__,"props":true,"meta":{"requiresAuth":true}},{"name":"uploaded-type","path":"/uploaded/:type","component":__pages_import_9__,"props":true,"meta":null,"requiresAuth":true}];

export default routes;