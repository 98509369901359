<script setup>
const props = defineProps({
  show: {
    type: Boolean,
    required: true,
    default: false,
  },
  data: {
    type: Object,
    required: true,
    default: () => ({
      title: 'Modal Title',
      text: 'Modal Text',
      html: '',
      showOk: true,
      okText: 'Ok',
      showCancel: true,
      cancelText: 'Cancel',
      showLink: false,
      link: '',
      linkText: '',
    }),
  },
})

const emit = defineEmits(['ok', 'cancel'])

function ok() {
  emit('ok')
}

function cancel() {
  emit('cancel')
}
</script>

<template>
  <div v-if="show">
    <div class="modal">
      <div class="modal-header">
        {{ data.title }}
      </div>
      <div class="modal-body">
        <div class="mb-3">
          {{ data.text }}
        </div>
        <div
          v-if="data.html"
          class="mb-3"
          v-html="data.html"
        />
        <div class="flex justify-evenly">
          <a v-if="data.showLink" :href="data.link" class="btn">{{ data.linkText }}</a>
          <button v-if="data.showOk" href="#" class="btn" @click.prevent="ok">
            {{ data.okText }}
          </button>
          <button v-if="data.showCancel" class="btn btn-outline" @click.prevent="cancel">
            {{ data.cancelText }}
          </button>
        </div>
      </div>
    </div>
    <div class="backdrop" />
  </div>
</template>

<style scoped>
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFC;
  width: 90%;
  border-radius: 20px;
  z-index: 1001;
}
.modal-header  {
  padding: 8px 24px;
  background-color: #FFDE6A;
  color: #000;
  font-size: 26px;
  font-weight: 700;
  text-align: left;
  border-radius: 18px 18px 0 0;
}
.modal-body {
  padding: 16px 24px;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(190, 190, 190, 0.50);
  z-index: 1000;
}
.btn {
  width: 136px;
  height: 40px;
  display: inline;
  color: #262626;
  font-size: 16px;
  font-weight: 500;
  padding: 11px;
  margin: 5px;
}
</style>
