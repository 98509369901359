import { defineStore } from 'pinia'

export const useStore = defineStore('store', {
  state: () => ({
    userData: getUserData(),
    accessToken: getAccessToken(),
    mercureToken: getMercureToken(),
    objectLimit: 10,
    counter: 0,
    hasFilter: false,
    images: [],
    stickers: [],
  }),
  actions: {
    setUserData(val) {
      this.userData = val
      setUserData(val)
    },
    setAccessToken(val) {
      this.accessToken = val
      setAccessToken(val)
    },
    setMercureToken(val) {
      if (val === null) {
        clearMercureToken()
        this.mercureToken = null
        
        return
      }
      this.mercureToken = val
      setMercureToken(val)
    },
    logout() {
      clearAllData()
      this.userData = null
      this.accessToken = null
      this.mercureToken = null
    },
  },
})
