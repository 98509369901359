const keyToken = 'accessToken'
const keyUser = 'userData'
const keyMercure = 'mercureToken'

export const isUserLoggedIn = () => !!(localStorage.getItem(keyUser) && localStorage.getItem(keyToken))

export const getUserData = () => JSON.parse(localStorage.getItem(keyUser))

export const getAccessToken = () => localStorage.getItem(keyToken) ?? ''

export const getMercureToken = () => localStorage.getItem(keyMercure) ?? ''

export const setUserData = val => localStorage.setItem(keyUser, JSON.stringify(val))

export const setAccessToken = val => localStorage.setItem(keyToken, val)

export const setMercureToken = val => localStorage.setItem(keyMercure, val)
export const clearMercureToken = () => localStorage.removeItem(keyMercure)

export const clearAllData = val => {
  localStorage.removeItem(keyUser)
  localStorage.removeItem(keyToken)
  localStorage.removeItem(keyMercure)
}
