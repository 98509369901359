import { createApp } from 'vue'
import './css/style.css'
import './assets/icons'
import App from './App.vue'
import { createPinia } from 'pinia'
import emitter from './utils/event'
import router from '@/router'
import { $api } from './utils/api'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import HoneybadgerVue from '@honeybadger-io/vue'
import Vue3Toasity from 'vue3-toastify'

const app = createApp(App)

const config = {
  apiKey: 'hbp_Xeg2fBH4JVHOvuFDkddzYBC06ncmyw2YivCN',
  environment: 'production',
}

if (import.meta.env.VITE_ENV === 'production') {
  app.use(HoneybadgerVue, config)
}

app.use(createPinia())
app.use(router)
app.use(PerfectScrollbar)
app.use(Vue3Toasity, { limit: 2 })
app.provide('emitter', emitter)
app.provide('$api', $api)

app.mount('#app')
