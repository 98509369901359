<script setup>
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { useStore } from '@/stores'

const emitter = inject('emitter')
const store = useStore()
let msgServer

onMounted(() => {
  if (store.userData?.id) {
    if (store.mercureToken) {
      subscribeEvent()
    } else {
      getMercureToken()
    }
  }
})
emitter.on(['logged-in'], e => {
  getMercureToken()
})

async function getMercureToken() {
  const data = await $api('token')
  if (data.token) {
    store.setMercureToken(data.token)
    subscribeEvent()
  }
}

function subscribeEvent() {
  const topics = [
    'ping',
    'deployed',
    'updated-' + store.userData.id,
  ]

  const query = topics.join('&topic=')

  msgServer = new EventSourcePolyfill(`${import.meta.env.VITE_MERCURE_URL}?topic=${query}`, {
    headers: {
      Authorization: `Bearer ${store.mercureToken}`,
    },
    heartbeatTimeout: 1805000,
  })
  msgServer.addEventListener('message', message => {
    try {
      const { data } = message
      if (data) {
        const json = JSON.parse(data)

        console.log('json', json)
        if (json.event.match(/deployed/)) {
          store.setMercureToken(null)
          window.location.reload()
        } else if (json.event === 'updated') {
          if (json.data.status === 5) {
            toast.success('Image approved', {
              autoClose: 3000,
              position: toast.POSITION.TOP_CENTER,
              theme: 'colored',
              transition: toast.TRANSITIONS.SLIDE,
            })
          } else if (json.data.status === 9) {
            toast.error('Image rejected', {
              autoClose: 3000,
              position: toast.POSITION.TOP_CENTER,
              theme: 'colored',
              transition: toast.TRANSITIONS.SLIDE,
            })
          }
          emitter.emit(json.event, json.data)
        } else {
          emitter.emit(json.event, json.data)
        }
      }
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log('Error:', ex)
    }
  })
}
</script>

<template>
  <div></div>
</template>
