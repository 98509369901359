import { createRouter, createWebHistory } from 'vue-router'
import routes from '~pages'
import { isUserLoggedIn } from '@/utils'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async to => {
  const isLoggedIn = isUserLoggedIn()
  if (to.meta.requiresAuth && !isLoggedIn) {
    return {
      path: '/',
    }
  }

})
export default router
