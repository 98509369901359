<script setup>
import { useStore } from '@/stores'

const store = useStore()

onMounted(() => {
  document.body.classList.add('index')
})

onUnmounted(() => {
  document.body.classList.remove('index')
})
</script>

<template>
  <div class="w-full mx-auto relative bg-white my-10 px-5 select-none">
    <div class="wrapper2">
      <h2 class="f-trap text-left text-[34px] fw-700 mb-2">
        Hi and Welcome<br>to ChuChamp
      </h2>
      <div class="text-sm text-left text-67 mb-5">
        Nice to meet you! Let's start our journey<br>to become champions together!
      </div>
      <img src="@/assets/images/chuchamp-landing.png" alt="ChuChamp" width="200" class="mx-auto my-[2rem] landing-img">
    </div>

    <RouterLink class="btn" to="/signup">
      Sign Up
    </RouterLink>
    <div class="text-sm text-center opacity-100 my-4 text-67">
      already have an account?
    </div>

    <RouterLink v-if="!store.userData" class="btn btn-outline" to="/login">
      Log In
    </RouterLink>
    <RouterLink v-else class="btn btn-outline" to="/home">
      Go to Home
    </RouterLink>
  </div>
</template>
