<script setup>
import { useStore } from '@/stores'
import Desktop from './components/Desktop.vue'
import Modal from '@/components/Modal.vue'
import Event from '@/components/Event.vue'

const store = useStore()
const $api = inject('$api')

const showModal = ref(false)
const modalData = ref({})

if (store.userData && store.accessToken) {
  // get profile
  const data = $api('profile', {
    ignoreResponseError: true,
    parseResponse: JSON.parse,
  })

  if (data.success) {
    store.setUserData(data.user)
  }
}

onMounted(() => {
  if (window.navigator.userAgent.match(/Line\//)) {
    modalData.value = {
      title: 'Unsupported',
      text: 'Please open in external browser.',
      showOk: false,
      showCancel: false,
      showLink: true,
      link: `https://${import.meta.env.VITE_APP_HOST}?openExternalBrowser=1`,
      linkText: 'Open Link',
    }
    showModal.value = true
  }
})

function openExternalBrowser() {

}
</script>

<template>
  <Desktop class="hidden md:flex" />
  <Suspense>
    <RouterView v-slot="{ Component }" class="md:hidden">
      <Transition name="fade">
        <Component :is="Component" />
      </Transition>
    </RouterView>
    <template #fallback>
      <div class="w-full text-center py-10">
        <svg class="animate-spin -ml-1 h-20 w-20 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          />
          <path class="opacity-75" fill="#FFDE6A" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
      </div>
    </template>
  </Suspense>
  <Event />
  <Modal
    :show="showModal"
    :data="modalData"
    @ok="openExternalBrowser"
  />
</template>

<style scoped>
.fade-enter-active {
  transition: all 0.2s ease-out;
}

.fade-leave-active {
  transition: all 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
